import React, { Component } from "react";
import { Table, Alert, Button } from "reactstrap";
import FadeIn from "react-fade-in";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

class TableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.location = "https://click.dailytechtrend.com/click";
    }, 40000);
  }
  

  showMore = () => {
    this.setState({
      showMore: !this.state.showMore
    });
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  referrer = () => {
    window.open("https://click.dailytechtrend.com/click", "_blank");
  };
  render() {
    var { showMore } = this.state;
    var fatFont = {
      fontWeight: "500"
    };

    var { device, brand, currency } = this.props;
    return (
      <div>
        <div className="container p-3 position-absolute mb-2 bg-white rounded">
          <h1 className="modal-title m-0 align-left" id="exampleModalLongTitle">
            Your password has been leaked
          </h1>
          <br />
          <p className="align-left">
            <b className='font-weight-bold'>
              {"  " + this.props.dataReceived.length + "  "} leaked password(s)
            </b>{" "}
            <br />
            Your data may be compromised. We recommend to use Antivirus
            protection for your {brand + " " + device}. <br></br>
          </p>
          <p className="font-weight-normal align-left">
            Install the recommended application - Discount Antivirus is
            available on your {brand + " " + device}. <br />{" "}
          </p>
          <p>
            {" "}
            Protect your {brand + " " + device} for only {currency}2.95 - to clean and
            improve the performance right away!
          </p>
          <FadeIn>
            <div class="modal-footer mt-3">
              <div class="left-side">
                <Button
                  variant="primary"
                  className="btn-link"
                  onClick={this.referrer}
                >
                  Install
                </Button>
              </div>
              <div class="divider"></div>
              <div class="right-side">
                <Button
                  variant="primary"
                  className="btn-link"
                  onClick={this.showMore}
                >
                  Leaks found {showMore ? "▲" : "▼"}
                </Button>
              </div>
            </div>
          </FadeIn>
          {showMore ? (
            <div className="container p-0">
              <FadeIn>
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  className="mt-5 bg-white "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Breached on:</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.dataReceived.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.Name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </FadeIn>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TableComponent;
