import React, { Component } from "react";
import {
  Button,
  Input,
  Modal,
  FormGroup,
  Label,
  Alert,
  ButtonToolbar,
  Spinner
} from "reactstrap";
import { Progress } from "reactstrap";

import TableComponent from "../components/Table";
import DemoFooter from "../../src/components/Footers/DemoFooter";

import "assets/scss/paper-kit.scss";
import queryString from "query-string";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataReceived: [],
      columns: [
        {
          Header: "Title",
          accessor: "Title"
        },
        {
          Header: "Source",
          accessor: "Source"
        },
        {
          id: "EmailCount",
          Header: "EmailCount"
        }
      ],

      email: "",
      loadPercent: 0,
      loadNow: false,
      falseEmail: false,
      noSpamFound: false,
      roundLoader: false,
      device: "",
      brand: "",
      timeOutModal: false,
      country: "",
      currency: "$",
      allowTimeOutModal: true
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const device = values.device;
    const brand = values.brand;
    const country = values.country;

    this.setState({ device, brand }, () => {
      console.log(this.state.device);
      console.log(this.state.brand);
      console.log(this.state.country);
    });

    setTimeout(this.func(), this.getRandomInt(120, 220) * 1000);

    if (country == "United Kingdom") {
      this.setState({
        currency: "£"
      });
    } else if (country == "Ireland") {
      this.setState({
        currency: "€"
      });
    }
    if (this.state.allowTimeOutModal) {
      setTimeout(() => {
        this.setState(
          {
            timeOutModal: true,
            allowTimeOutModal:false

          },
          () => {
            console.log(this.state.timeOutModal);
            this.speaker();
          }
        );
      }, 20000);
    }
  }

  // componentDidUpdate(prevState) {
  //   if ((this.state.allowTimeOutModal = prevState.allowTimeOutModal)) {
  //     setTimeout(() => {
  //       this.setState(
  //         {
  //           timeOutModal: true
  //         },
  //         () => {
  //           console.log(this.state.timeOutModal);
  //           this.speaker();
  //         }
  //       );
  //     }, 3000);
  //   }
  // }

  getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  func = () => (
    (navigator.vibrate =
      navigator.vibrate ||
      navigator.webkitVibrate ||
      navigator.mozVibrate ||
      navigator.msVibrate),
    navigator.vibrate([1000, 500, 1000, 500, 1000, 500, 1000, 500, 1000, 500])
  );

  speaker = () => {
    var translation =
      "Your device may slow down and the battery may run down quickly.";

    var b = new SpeechSynthesisUtterance();
    var a = speechSynthesis.getVoices();
    b.text = translation;
    b.voice = a[4];
    b.lang = "en-GB";

    b.voiceURI = "native";
    b.volume = 1;
    b.rate = 1;
    b.pitch = 1;
    b.text = translation;
    speechSynthesis.speak(b);
  };

  dismissFalseEmail = () => {
    this.setState({ falseEmail: !this.state.falseEmail });
  };

  referrer = () => {
    window.open("https://click.dailytechtrend.com/click", "_blank");
  };

  render() {
    var { device, brand, country } = this.state;

    const columns = [
      {
        Header: "Title",
        accessor: "Title" // String-based value accessors!
      },
      {
        Header: "Source",
        accessor: "Source",
        Cell: props => <span className="number">{props.value}</span> // Custom cell components!
      },
      {
        id: "EmailCount", // Required because our accessor is not a string
        Header: "Email Count",
        accessor: d => d.EmailCount // Custom value accessors!
      }
    ];

    var faIcon = {
      margin: "5px",
      fontSize: "25px"
    };

    var headerText = {
      fontWeight: "700"
    };

    const fetchPwned = emailState => {
      function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
      this.setState({
        allowTimeOutModal: false
      })

      if (validateEmail(emailState)) {
        this.setState({
          roundLoader: true
        });
      }
      return fetch(
        `https://cors-anywhere.herokuapp.com/https://haveibeenpwned.com/api/v3/breachedaccount/${this.state.email}`,
        {
          headers: {
            "hibp-api-key": "5a06ea99eec6442495bd685db90e0269"
          }
        }
      )
        .then(response => response.json())
        .then(data => {
          const dataReceived = data.map(item => {
            return item;
          });
          console.log(dataReceived);
          this.setState({ dataReceived: dataReceived });
          this.setState({
            loadNow: true,
            falseEmail: false
          });

          const int = setInterval(() => {
            this.setState({
              loadPercent:
                this.state.loadPercent + Number.parseInt(Math.random() * 30)
            });
          }, 450);

          if (this.state.loadPercent > 100) {
            clearInterval(int);
          }
        })
        .catch(err => {
          /**
            |--------------------------------Emailstate------------------
            | Jeigu email yra false
            |--------------------------------------------------
            */
          if (!validateEmail(emailState)) {
            return (
              this.setState({
                falseEmail: true
              }),
              setTimeout(() => {
                this.setState({
                  loadPercent: 0,
                  loadNow: false,
                  falseEmail: false,
                  noSpamFound: false
                });
              }, 4000)
            );
          } else
          /**
            |--------------------------------------------------
            | Jeigu email tikras, bet clean
            |--------------------------------------------------
            */
            return (
              this.setState({
                roundLoader: true
              }),
              setTimeout(() => {
                this.setState({
                  noSpamFound: true
                });
              }, 3000)
            );
        });
    };

    return (
      <div className="position-relative">
        <Modal isOpen={true} className="reducedSize">
          <Alert
            color="danger"
            isOpen={this.state.falseEmail}
            className="alert"
          >
            Please enter a valid e-mail
          </Alert>
          {this.state.loadPercent > 130 ? (
            <TableComponent
              dataReceived={this.state.dataReceived}
              device={this.state.device}
              brand={this.state.brand}
              currency={this.state.currency}
            />
          ) : null}
          {this.state.loadNow && this.state.loadPercent < 125 ? (
            <div className="modal-header">
              <Progress
                striped
                max="100"
                value={this.state.loadPercent}
                barClassName="progress-bar-primary"
              />
            </div>
          ) : (
            <div>
              {this.state.loadPercent < 110 ? (
                <div className="container">
                  {this.state.timeOutModal ? (
                    <div className="modal-body text-center">
                      Your device may slow down and the battery may run down
                      quickly.
                      <button
                        className="btn mt-3"
                        onClick={() =>
                          this.setState({
                            timeOutModal: false,
                            allowTimeOutModal: false
                          })
                        }
                      >
                        OK
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="modal-header">
                        <h1
                          className="modal-title m-0"
                          id="exampleModalLongTitle"
                          style={headerText}
                        >
                          {this.state.noSpamFound
                            ? null
                            : "Check if your personal data is at risk"}
                        </h1>
                        <h1
                          className="modal-title m-0"
                          id="exampleModalLongTitleGreen"
                          style={headerText}
                        >
                          {this.state.noSpamFound ? `No leaks found` : null}
                        </h1>

                        <br />
                        {this.state.noSpamFound ? (
                          <div>
                            <p className="font-weight-normal align-left">
                              Your identity is safe for now. We recommend to use
                              Antivirus protection for your
                              {brand + " " + device}.
                            </p>
                            <p className="font-weight-normal align-left">
                              Install the recommended application - Discount
                              Antivirus is available on your
                              {brand + " " + device}.
                            </p>
                            <p className="font-weight-normal align-left">
                              Protect your {brand + " " + device} for only €2.95
                              - to clean and improve the performance right away!
                            </p>
                          </div>
                        ) : null}
                      </div>

                      {this.state.noSpamFound ? (
                        <div className="container text-center mt-5 modal-footer">
                          <Button
                            variant="primary"
                            className="btn-link my-3"
                            size="m"
                            onClick={this.referrer}
                          >
                            Install
                          </Button>
                        </div>
                      ) : (
                        <div className="modal-body p-0">
                          <div className="container p-0">
                            <p className="font-weight-normal">
                              <p>
                                Due to recent reports of Malware, TotalAV have
                                released an antivirus update compatible for your
                                {" " + brand + " " + device}.
                              </p>
                              <p>
                                <br />
                                <div>
                                  <img
                                    className="float-left d-inline avImg"
                                    src="https://firstpushbucket.s3.eu-west-3.amazonaws.com/Amazon+Master+Lander/log.png"
                                  />
                                  <p>
                                    A device without antivirus, could be exposed
                                    to viruses and a faster draining battery.
                                  </p>
                                </div>
                              </p>
                              <br />
                              Fill in your email and find if your identity is
                              exposed or not.
                              <br /> <br />
                            </p>
                          </div>
                          <div className="mt-2 text-center">
                            <i
                              className="fa fa-envelope w-auto"
                              style={faIcon}
                            />
                            <Input
                              placeholder="Your Email..."
                              type="text"
                              onChange={e => {
                                let { email } = this.state;
                                email = e.target.value;
                                this.setState({
                                  email,
                                  allowTimeOutModal: false
                                });
                                if (email === "") {
                                  this.setState({
                                    falseEmail: false
                                  });
                                }
                              }}
                              value={this.state.email}
                              className="d-inline w-75"
                            />
                          </div>

                          <div className="modal-footer mt-3">
                            <div className="left-side">
                              <Button
                                variant="primary"
                                className="btn-link"
                                onClick={() => fetchPwned(this.state.email)}
                              >
                                {this.state.roundLoader ? (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                {" CHECK"}
                              </Button>
                            </div>
                            <div className="divider" />
                            <div className="right-side">
                              <Button variant="primary" className="btn-link">
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default Index;
